import React, { useEffect, useState } from 'react'
import ReactCodeInput from 'react-code-input'
import { get_check_code, post_call_code } from '../services/api'
import Loader from '../components/Loader'

const VerificationComponent: React.FC<{ closeModal: () => void, submit: () => void }> = ({ closeModal, submit }) => {
  const timerSeconds = 60
  const [code, setCode] = useState('')
  const [time, setTime] = useState(timerSeconds)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [timerID, setTimerID] = useState<any>()
  const [step, setStep] = useState<'' | 'code_sent' | 'error_code_sent' | 'code_error'>('')
  
  // Получаем номер телефона из локального хранилища
  const phone = localStorage.getItem('phone') ?? ''

  useEffect(() => {
    if (phone) {
      sendCode()
    }
    return () => clearInterval(timerID)
  }, [phone])

  useEffect(() => {
    if (code.length === 4) checkCode()
  }, [code])

  const timerStart = (count: number) => {
    clearInterval(timerID)
    setTime(count)
    const intervalID = setInterval(() => {
      count = count - 1
      if (count < 1) {
        clearInterval(intervalID)
      }
      setTime(count)
    }, 1000)
    setTimerID(intervalID)
  }

  const sendCode = (retry: boolean = false) => {
    if (!phone) {
      setError('Номер телефона не найден')
      return
    }
    setError('')
    setLoading(true)
    let query = `?phone=${phone}`
    if (retry) query += '&retry=true'

    post_call_code(query)
      .then(() => {
        setStep('code_sent')
        timerStart(timerSeconds)
      })
      .catch((err: { response: { status: any } }) => {
        setStep('error_code_sent')
        timerStart(timerSeconds)
        switch (err.response.status) {
          case 400:
            setError('Код уже отправлен')
            break
          case 500:
            setError('Ошибка сервиса')
            break
          default:
            setError('Не удалось отправить код')
            break
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const checkCode = () => {
    setError('');
    setLoading(true);
    let query = `?phone=${phone}&code=${code}`;
  
    get_check_code(query)
      .then((res: { data: { status: string } }) => {
        if (res.data.status === 'BAD') {
          setStep('code_error');
          setError('Неверный код');
          return; // Прерываем выполнение, если код неверный
        }
  
        if (res.data.status === 'OK') {
          window.location.href = '/cards';
         
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getTime = (time: number): string => {
    if (time < 10) return `0:0${time}`
    else if (time < 60 && time > 9) return `0:${time}`
    const minute = Math.floor(time / 60)
    const second = time % 60
    if (second < 10) return `${minute}:0${second}`
    else return `${minute}:${second}`
  }
  const handleBackClick = () => {
    // Очищаем локальное хранилище
    localStorage.clear()
    // Редиректим на пустой путь (или на нужный путь)
    window.location.replace('/')
  }

  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="w-96 h-80 rounded-xl bg-white z-20 shadow-d border-2 border-gray-500 flex flex-col relative">
        {loading ? (
          <>
            <div className="absolute w-full h-full bg-gray-500 opacity-50" />
            <div className="absolute w-full h-full flex items-center justify-center">
              <Loader color="rgb(79, 70, 229)" />
            </div>
          </>
        ) : null}
        <form
          onSubmit={(e) => { e.preventDefault(); submit() }}
          className="flex flex-col justify-center items-center w-full h-full text-black gap-4"
        >
          <span>Код отправлен на {phone}</span>
          <span className="font-bold">Введите код подтверждения</span>
          <ReactCodeInput
            type="number"
            fields={4}
            name="code"
            inputMode="tel"
            value={code}
            onChange={e => setCode(e)}
          />
          {!error.length && step === 'code_sent' ? (
            <>
              <span className="text-white text-center">
                Код успешно отправлен на {phone}
              </span>
              {time < 1 ? (
                <button
                  className="p-2 rounded-xl bg-green-600 hover:bg-green-700 transition"
                  onClick={(e) => {
                    e.preventDefault()
                    sendCode(true)
                  }}
                >
                  Отправить код снова
                </button>
              ) : (
                <span className="text-white text-center">
                  Повторная отправка возможна через {getTime(time)}
                </span>
              )}
            </>
          ) : null}
          {error.length && step === 'error_code_sent' ? (
            <>
              <span className="text-red-600 text-center font-bold">
                {error}
              </span>
              {time < 1 ? (
                <button
                  className="p-2 rounded-xl bg-green-600 hover:bg-green-700 transition cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault()
                    sendCode(true)
                  }}
                >
                  Отправить код снова
                </button>
              ) : (
                <span className="text-black text-center">
                  Повторная отправка возможна через {getTime(time)}
                </span>
              )}
            </>
          ) : null}
          {error.length && step === 'code_error' ? (
            <>
              <span className="text-red-600 text-center font-bold">
                {error}
              </span>
              {time < 1 ? (
                <button
                  className="p-2 rounded-xl bg-green-600 hover:bg-green-700 transition cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault()
                    sendCode(true)
                  }}
                >
                  Отправить код снова
                </button>
              ) : (
                <span className="text-black text-center">
                  Повторная отправка возможна через {getTime(time)}
                </span>
              )}
            </>
          ) : null}
          {/* Кнопка назад */}
          <button
            type="button"
            className="mt-4 p-2 rounded-xl bg-blue-600 hover:bg-blue-700 transition"
            onClick={handleBackClick}
          >
            Назад ко входу
          </button>
        </form>
      </div>
      <div
        className="absolute inset-0 opacity-25 bg-black z-10"
        onClick={() => closeModal()}
      />
    </div>
  )
}


export default VerificationComponent
