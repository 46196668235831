import Login from './pages/Login'
import {
    CLIENTS_ROUTE,
    DESIGN_ROUTE,
    DESIGN_ROUTE_BACK,
    DESIGN_ROUTE_PAY,
    DESIGN_ROUTE_SITE,
    DESIGN_ROUTE_WALLET,
    GUEST_CARDS,
    GUEST_MAIN,
    GUEST_REGISTER,
    LEAVE_FEEDBACK_ROUTE,
    LOGIN_ROUTE,
    MAIN_ROUTE,
    MAIN_ROUTE_CARD,
    MAIN_ROUTE_FEEDBACK,
    PUSH_MSG_EDIT_ROUTE,
    PUSH_MSG_GEO_ROUTE,
    PUSH_MSG_ROUTE,
    PUSH_MSG_TEXT_ROUTE,
    REVIEWS_ADD_ROUTE,
    REVIEWS_EDIT_ROUTE,
    REVIEWS_ROUTE,
    REVIEWS_STATS_ROUTE,
    SETTINGS_ROUTE,
    TRIGGERS_ROUTE,
    UTM_ROUTE
} from './utils/routesConsts'
import { IRoutes } from './models/IRoutes'
import Main from './pages/Main'
import Design from './pages/Design'
import Clients from './pages/Clients'
import PushMessages from './pages/PushMessages'
import Reviews from './pages/Reviews'
import Triggers from './pages/Triggers'
import Settings from './pages/Settings'
import TextPush from './pages/PushMessages/TextPush'
import GeoPush from './pages/PushMessages/GeoPush'
import EditPush from './pages/PushMessages/EditPush'
import AddReviews from './pages/Reviews/AddReviews'
import StatsReviews from './pages/Reviews/StatsReviews'
import EditReviews from './pages/Reviews/EditReviews'
import PayDesign from './pages/Designs/PayDesign'
import WalletDesign from './pages/Designs/WalletDesign'
import SiteDesign from './pages/Designs/SiteDesign'
import BackDesign from './pages/Designs/BackDesign'
import LeaveFeedback from './pages/LeaveFeedback'
import Index from './pages/Index'
import GuestCards from './pages/GuestCards'
import RegisterGuest from './pages/RegisterGuest'
import FeedbackStatistics from '../src/pages/FeedbackStatistics'
import СardStatistics from '../src/pages/CardStatistics'
import UtmPage from './pages/Utm'
import VerificationPage from './pages/VerificationPage'


export const privateRoutes: IRoutes[] = [
    {
        path: LOGIN_ROUTE,
        component: Login
    }
]

export const publicRoutes: IRoutes[] = [
    {
        path: MAIN_ROUTE,
        component: Main
    },
    {
        path: DESIGN_ROUTE,
        component: Design
    },
    {
        path: DESIGN_ROUTE_PAY,
        component: PayDesign
    },
    {
        path: DESIGN_ROUTE_WALLET,
        component: WalletDesign
    },
    {
        path: DESIGN_ROUTE_SITE,
        component: SiteDesign
    },
    {
        path: DESIGN_ROUTE_BACK,
        component: BackDesign
    },
    {
        path: CLIENTS_ROUTE,
        component: Clients
    },
    {
        path: PUSH_MSG_ROUTE,
        component: PushMessages
    },
    {
        path: PUSH_MSG_TEXT_ROUTE,
        component: TextPush
    },
    {
        path: PUSH_MSG_GEO_ROUTE,
        component: GeoPush
    },
    {
        path: PUSH_MSG_EDIT_ROUTE,
        component: EditPush
    },
    {
        path: REVIEWS_ROUTE,
        component: Reviews
    },
    {
        path: REVIEWS_ADD_ROUTE,
        component: AddReviews
    },
    {
        path: REVIEWS_EDIT_ROUTE,
        component: EditReviews
    },
    {
        path: REVIEWS_STATS_ROUTE,
        component: StatsReviews
    },
    {
        path: TRIGGERS_ROUTE,
        component: Triggers
    },
    {
        path: SETTINGS_ROUTE,
        component: Settings
    },
    {
        path: MAIN_ROUTE_FEEDBACK,
        component: FeedbackStatistics
    },
    {
        path: MAIN_ROUTE_CARD,
        component: СardStatistics
    },
     {
        path: UTM_ROUTE,
        component: UtmPage
    }
]

export const guestPrivateRoutes: IRoutes[] = [
    {
        path: LEAVE_FEEDBACK_ROUTE,
        component: LeaveFeedback
    },
    {
        path: GUEST_CARDS,
        component: GuestCards
    },
    {
        path: '/verification',
        component: VerificationPage,
    },
]




export const guestPublicRoutes: IRoutes[] = [
    {
        path: GUEST_MAIN,
        component:  Index
    },
]

export const guestRegisterRoute: IRoutes[] = [
    {
        path: GUEST_REGISTER,
        component: RegisterGuest
    }
]
